import { h } from 'preact';
import style from './style.css';
import img1 from '../../assets/img/intro-carousel/1.jpg';
import img2 from '../../assets/img/intro-carousel/2.jpg';
import img3 from '../../assets/img/intro-carousel/3.jpg';
import img4 from '../../assets/img/intro-carousel/4.jpg';
import img5 from '../../assets/img/intro-carousel/5.jpg';

const Banner = () => (
	<div class={style.banner}>
		<section id="intro">
			<div class="intro-container">
				<div id="introCarousel" class="carousel  slide carousel-fade" data-ride="carousel">
					<ol class="carousel-indicators" />

					<div class="carousel-inner" role="listbox">
						<div class="carousel-item active">
							<div class="carousel-background">
								<img src={img3} alt="" />
							</div>
							<div class="carousel-container">
								<div class="carousel-content">
									<h2>Develop softwares as services on Eco Platform</h2>
									<h3>(Phát triển phần mềm dạng dịch vụ trên nền tảng hệ sinh thái)</h3>
									<p>
										Doanh nghiệp bạn có gặp khó khăn trong việc triển khai các phần mềm sao cho thống nhất, có sự kế thừa liên tục?
									</p>
									{/* <h2>The platform is ready at https://myworkspace.vn</h2> */}

									<a href="mailto:ThachLN@mks.com.vn?subject=Contact me to demo the platform?Body=Please contact me by ..." target="_blank" class="btn-get-started scrollto">
									Contact us
									</a>
								</div>
							</div>

						</div>
						<div class="carousel-item">
							<div class="carousel-background">
								<img src={img1} alt="" />
							</div>
							<div class="carousel-container">
								<div class="carousel-content">
									<h2>Implement and Operate Software Internship Factory for Universites | Colleges</h2>
									<h3>(Triển khai và Vận hành Xưởng Thực tập Doanh nghiệp cho trường Đại Học, Cao Đẳng)</h3>
									<p>
										Trường bạn có đang gặp khó khăn hoặc muốn cải tiến chất lượng Thực tập Doanh nghiệp cho sinh viên Phần mềm?
									</p>
									<a href="mailto:ThachLN@mks.com.vn?subject=Liên lạc lại tôi để giới thiệu dịch vụ Xưởng Thực Tập Phần Mềm?Body=Hi Thach - Mekong Solution Company ..." target="_blank" class="btn-get-started scrollto">
									Contact us
									</a>
								</div>
							</div>
						</div>

					</div>

					<a class="carousel-control-prev" href="#introCarousel" role="button" data-slide="prev">
						<span class="carousel-control-prev-icon ion-chevron-left" aria-hidden="true" />
						<span class="sr-only">Previous</span>
					</a>

					<a class="carousel-control-next" href="#introCarousel" role="button" data-slide="next">
						<span class="carousel-control-next-icon ion-chevron-right" aria-hidden="true" />
						<span class="sr-only">Next</span>
					</a>
				</div>
			</div>
		</section>
	</div>
);

export default Banner;
